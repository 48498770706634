.bottom-header {
  height: 60px;
  width: 100%;
  background-color: #353e48;
  display: flex;
  align-items: center;
}

.bottom-header-ul {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.bottom-header-ul li {
  list-style: none;
  width: 130px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.bottom-header-ul li:hover {
  background-color: #71BE00;
}

.bottom-header-ul li a {
  text-decoration: none;
  color: #e2e2e2;
  height: 100%;
  display: flex;
  align-items: center;
}