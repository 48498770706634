.Card {
  width: 30%;
  min-width: 200px;
  margin: 20px;
  background: #FFF;
  border-radius: 1px;
  padding-bottom: 15px;
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  transition: box-shadow .15s ease .05s;
  height: calc(100% - 30px);
  display: inline-block;
  -webkit-box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.75);
}

.Card-img {
  width: 100%;
  height: 400px;
  overflow: hidden;
  background-color: #fff;
}

.Card-info {
  border-bottom: 1px solid #cecece;
  padding-bottom: 40px;
  width: 90%;
  margin: 20px auto;
}

.Card-price {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Card-switch .switcher {
  width: 30px;
  margin: 20px;
  background-color: #CBCFD4;
  cursor: pointer;
  padding: 10px;
}

.Card-switch .active {
  width: 30px;
  margin: 20px;
  color: #fff;
  background-color: #71BE00;
}

.Card-counter {
  color: #afafaf;
  height: 50px;
}

.Card-counter .counter {
  background-color: #f6f6f6;
  width: 30px;
  height: 30px;
  margin: 0 20px;
  cursor: pointer;
}