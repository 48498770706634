body {
  font-family: 'Open Sans',sans-serif;
  background-color: #f2f3f5;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v115/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}


.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.row {
  flex-direction: row;
}

.flex {
  display: flex;
  width: 100%;
}

.space-between {
  justify-content: space-between;
}

.bold {
  font-weight: 700;
}

.greenBg {
  background-color: #71BE00;
}

.green {
  color: #71BE00;
}

.dark-green {
  color: #426f03;
}

.red {
  color: #f75e5e;
}

.green-bg {
  background-color: #71BE00;
}

.small {
  font-size: 13px;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-dark-grey {
  width: 80%;
  color: #FFF;
  text-transform: uppercase;
  background: #666;
  border-radius: 1px;
  padding: 9px;
  margin: 10px auto;
  cursor: pointer;
}

.btn-dark-red {
  width: 80%;
  color: #FFF;
  text-transform: uppercase;
  background: #f75e5e;
  border-radius: 1px;
  padding: 9px;
  margin: 10px auto;
  cursor: pointer;
}

.btn-light-grey {
  width: 80%;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding: 9px;
  background: #999;
  border-radius: 1px;
  margin: 10px auto;
  cursor: pointer;
  text-decoration: none;
}

.btn-light-green {
  width: 80%;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  padding: 9px;
  background: #71BE00;
  border-radius: 1px;
  margin: 10px auto;
  cursor: pointer;
  text-decoration: none;
}

.preserved-rights {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 40px;
  position: absolute;
  bottom: 30px;
  right: 10px;
}

.preserved-rights div{
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 5px;
  color: #808387;
}

.section {
  margin: 0 5%;
}

.section-header {
  color: #30363D;
  display: flex;
  height: 100px;
  align-items: center;
  font-weight: 700;
  font-size: 34px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ededed;
}

.section-w { 
  background-color: #FFF;
  box-shadow: 0px 1px 5px rgb(0 0 0 / 3%);
  padding: 30px;
  height: 100%;
  margin-bottom: 80px;
}

.general-list {
 margin: 20px 0;
}

.general-list li {
  margin: 10px 0;
}

.text-s {
  margin: 20px 0;
}

.grey {
  color: #898989;
}

.center-a {
  text-align: center;
}

.link-reset {
  text-decoration: none;
}

@media screen and (max-width: 600px) {
  .bottom-header-ul {
    flex-direction: column;
  }
  .bottom-header {
    height: auto;
  }
  .Card {
    width: 90%;
    margin: 20px 0;
  }
  .baner {
    width: 90%;
    margin: 0 auto;
  }
}