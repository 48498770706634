.basket-list {
  z-index: 999;
  position: absolute;
  background-color: #fff;
  top: 40px;
  right: 0;
  width: 300px;
  min-height: 100px;
  border-radius: 8px;
  padding: 8px;
  -webkit-box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 15px -7px rgba(0,0,0,0.75);
}

.basket-list-delete {
  position: absolute;
  right: 0;
}

.basket-list li {
  position: relative;
  text-decoration: none;
  list-style: none;
  display: flex;
  border-bottom: 1px solid #cecece;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.basket-list a {
  text-decoration: none;
}