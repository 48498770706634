.info-header {
  height: 50px;
  width: 100%;
  background-color: #353e48;
  display: flex;
  align-items: center;
}

.info-header {
  width: 100%;
}

.address-info-dropUp {
  color: #e5e5e5;
  display: flex;
  align-items: center;
  font-family: monospace;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #4c535c;
  cursor: pointer;
  margin-left: 50px;
}

.info-dropDown {
  height: 50px;
  width: 70%;
  font-size: 13px;
  padding-left: 30px;
  box-shadow: 5px 12px 30px rgb(0 0 0 / 35%);
  display: flex;
  align-items: center;
  background-color: #fff;
  position: absolute;
}

.schedule-info-dropUp {
  color: #e5e5e5;
  display: flex;
  align-items: center;
  font-family: monospace;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-left: 50px;
}

.baner {
  height: 100px;
  width: 100%;
  background-color: #f2f3f5;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.company-name {
  font-size: 20px;
}

.basket {
  position: relative;
  cursor: pointer;
}

.basket-number {
  position: absolute;
  top: 10px;
  right: -12px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  background: #f75e5e;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
}