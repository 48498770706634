.order-form {
  width: 80%;
  margin: 30px auto;
}

.input-row {
  margin: 20px 0;
}

.modal-confirmation {
  height: 200px;
  display: flex;
  flex-direction: column;
}